import React from "react"
import PropTypes from "prop-types"
import { motion, AnimateSharedLayout } from "framer-motion"

import CookieBanner from "../CookieBanner/CookieBanner"

import "../../css/desktop-styles.css"
import "../../css/mobile-styles.css"

const Layout = ({ children }) => {
  return (
    <>
      <div
        style={{
          height: "100vh",
          flexDirection: "column",
          textAlign: "center",
          position: "relative",
        }}
      >
        <AnimateSharedLayout>
          <main>
            <motion.div id={"page-container"}>
              {children}
              <CookieBanner />
            </motion.div>
          </main>
        </AnimateSharedLayout>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
