import React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

import "./cookie-banner.css"

const CookieBanner = () => {
  return (
    <>
      <CookieConsent
        location="bottom"
        cookieName="gatsby-gdpr-google-analytics"
        disableStyles={true}
        containerClasses="cookie-banner-container"
        contentClasses="cookie-banner-text"
        buttonClasses="cookie-banner-accept"
        buttonText="YES, I AGREE"
        enableDeclineButton
        declineButtonClasses="cookie-banner-decline"
        declineButtonText="NO THANKS"
        expires={150}
      >
        <span className={"paragraph uppercase"}>
          By using our site you agree to our{" "}
          <Link to={"/privacy"}>privacy policy</Link>.
        </span>
      </CookieConsent>
    </>
  )
}

export default CookieBanner
