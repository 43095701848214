import React, { useContext } from "react"
import { motion } from "framer-motion"

import DynamicLink from "../../utils/dynamicLink"

import { GlobalStateContext } from "../../context/GlobalContextProvider"

import "./button.css"

const Button = ({
  text,
  style,
  to,
  onClick,
  animate,
  className,
  initial,
  disabled = false,
}) => {
  const state = useContext(GlobalStateContext)
  const { theme } = state

  const variants = {
    initial: {
      backgroundColor: theme.primary,
      color: theme.secondary,
      cursor: "pointer",
    },
    hover: {
      backgroundColor: theme.accent,
      color: theme.secondary,
      cursor: "pointer",
    },
    selected: {
      backgroundColor: theme.accent,
      color: theme.secondary,
      cursor: "pointer",
    },
    disabled: {
      backgroundColor: "#eee",
      color: theme.secondary,
      cursor: "default",
    },
  }

  return (
    <DynamicLink to={to} style={{ width: "100%", height: "auto" }}>
      <motion.button
        id={"button-container"}
        style={style}
        onClick={onClick}
        variants={variants}
        initial={initial}
        whileHover={disabled ? "disabled" : "hover"}
        transition={{ duration: 0.2 }}
        animate={animate}
        disabled={disabled}
        className={className}
      >
        {text}
      </motion.button>
    </DynamicLink>
  )
}

export default Button
